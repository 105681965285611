<template>
  <div>
    <slot>-</slot>
  </div>
</template>

<script>
import $ from 'jquery'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ViewBase',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    window.base = this
    if (!window.GPSWatches) window.GPSWatches = []
    window.GPSWatches.map(id => {
      navigator.geolocation.clearWatch(id)
    })
    window.GPSWatches = []
  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    showToast(message, messageIcon = 'SuccessIcon', messageVariant = 'success', messageText = '') {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: message,
          icon: messageIcon,
          variant: messageVariant,
          text: messageText,
        },
      })
    },
    showToastError(message) {
      this.showToast(message ?? 'Ocorreu um problema, tente novamente', 'XIcon', 'danger')
    },
    showLoading() {
      $('#pageLoading').stop().show()
    },
    hideLoading() {
      $('#pageLoading').stop().hide()
    },
    checkError(error) {
      if (error?.response?.status === 401) this.$router.push({ name: 'not-authorized' })
    },
  },
}
</script>
